import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { apiGet, apiPost } from '../../../../api';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { Accordeon, Breadcrumbs, Dropzone, Input, Modal, Pagination } from '../../../components';
import Index from '../../../components/Spinner';
import { Gear, TrashCan } from '../../../icons';
import './index.scss';

const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

const ViewReport = () => {
  const { pageTrigger, pageRule, searchTrigger, searchRule, content } = useUrl();

  const state = useSelector(state => state);
  const { id } = useParams();
  // const urlParams = getUrlParams()
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [files, setFiles] = useState([]);
  const [filterTrigger, setFilterTrigger] = useState(searchTrigger);
  const [filterRule, setFilterRule] = useState(searchRule);
  const [pageInfo, setPageInfo] = useState(content || 1);
  const [modal, setModal] = useState(false);
  const [response, setResponse] = useState(null);
  const [showDependentModal, setShowDependentModal] = useState(false);
  const [dependents, setDependents] = useState(null);
  const [dependentForAddID, setDependentForAddID] = useState(null);
  const [reportTemplates, setReportTemplates] = useState(null);

  const viewModal = () => {
    setModal(true);
  };

  const changeRuleSearch = v => {
    history.push(updateUrl({ key: 'pageRule', value: '1' }));
    history.push(updateUrl({ key: 'searchRule', value: v }));
    setFilterRule(v);
  };

  const changeTriggerSearch = v => {
    history.push(updateUrl({ key: 'pageTrigger', value: '1' }));
    history.push(updateUrl({ key: 'searchTrigger', value: v }));
    setFilterTrigger(v);
  };

  const changeContent = c => {
    history.push(updateUrl({ key: 'content', value: c }));
    setPageInfo(c);
  };

  // useEffect(() => {
  // 	history.push(updateUrl({ key: "pageRule", value: pageRules }))
  // 	setPageRules(pageRules)
  // }, [pageRules])

  // useEffect(() => {
  // 	history.push(updateUrl({ key: "pageTrigger", value: page }))
  // 	setPage(page)
  // }, [page])

  // useEffect(() => {
  // 	setPageInfo(urlParams.content == "rules" ? 2 : 1)
  // }, [])

  // useEffect(() => {
  // 	if (pageInfo === 1) {
  // 		history.replace(history.location.pathname + "?" + "content=triggers")
  // 	}
  // 	if (pageInfo === 2) {
  // 		history.replace(history.location.pathname + "?" + "content=rules")
  // 	}
  // }, [pageInfo])

  const [viewModalUpload, setViewModalUpload] = useState(false);

  useEffect(() => {
    actualizeResponse();

    apiGet({ url: `/report-template?limit=10000` }).then(res => {
      setReportTemplates(res.data.models);
    });
  }, []);

  const actualizeResponse = () => {
    apiGet({ url: `/report-template/view?id=${id}` }).then(res => {
      setResponse(res.data);
    });
  };

  const { response: treeReport, loading: treeLoading } = useFetch({
    url: `/report-template/reference?id=${id}`,
  });

  // получение триггеров

  let urlTrigger = `/trigger?limit=15&template_id=${id}`;

  if (pageTrigger) {
    urlTrigger += `&page=${pageTrigger}`;
  }

  if (searchTrigger) {
    urlTrigger += `&name=${searchTrigger}`;
  }

  const { response: triggers, fetchData } = useFetch({
    url: urlTrigger,
  });

  //Получение правил

  let urlRule = `/rule?limit=15&template_id=${id}`;

  if (pageRule) {
    urlRule += `&page=${pageRule}`;
  }

  if (searchRule) {
    urlRule += `&name=${searchRule}`;
  }

  const { response: rules, fetchData: refreshRule } = useFetch({
    url: urlRule,
  });

  // получение списка организаций

  const { response: companyData } = useFetch({
    url: `/organization?`,
  });

  const uploadFileAlert = () => {
    confirmAlert({
      message: 'Загрузите файл',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const triggerDelAlert = () => {
    confirmAlert({
      message: 'Триггер успешно удален',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const ruleDelAlert = () => {
    confirmAlert({
      message: 'Правило успешно удалено',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const templateSuccessUploadAlert = () => {
    confirmAlert({
      message: 'Эталон успешно загружен',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const copyTriggerAlert = () => {
    confirmAlert({
      message: 'Триггер добавлен в буфер обмена, вставьте его в поле',
      buttons: [
        {
          label: 'Ок',
          onClick: () => {},
        },
      ],
    });
  };

  const deleteTriggerAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить триггер?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteTrigger(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const deleteRuleAlert = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить правило?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteRule(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const showModalNewEvent = isShow => {
    setViewModalUpload(isShow);
  };

  const onFileRemove = fName => {
    const fList = files.filter(f => f.name !== fName);

    setFiles(fList);
  };

  const handlerUploadFile = e => {
    setFiles(e);
  };

  const sendUpload = () => {
    if (!files.length) {
      uploadFileAlert();
      return;
    }

    if (!sending) {
      let formData = new FormData();
      formData.append('reference', files[0]);

      apiPost({
        url: `/report-template/create-reference?id=${id}`,
        postData: formData,
      })
        .then(res => {
          if (res.error) {
            console.error('Создание эталона');
            return;
          }

          showModalNewEvent(false);
          fetchData();
          templateSuccessUploadAlert();
        })
        .finally(() => setSending(false));
    }
  };

  // const fTrigger = item => {
  // 	setFilterTrigger(item)
  // 	setPage(1)
  // }

  // const fRule = item => {
  // 	setFilterRule(item)
  // 	setPage(1)
  // }

  const TreeItem = useCallback(
    ({ data }) => {
      const [btnType, setBtnType] = useState(null);
      const [sending, setSending] = useState(false);
      const [prefix, setPrefix] = useState('');

      const {
        _id,
        title,
        // isTable,
        content,
        children,
        callback,
      } = data;

      const copyTrigger = id => {
        if (!sending) {
          setSending(true);

          let url = `/report-element/copy?id=${id}`;
          if (btnType) {
            url += `&copyMode=${btnType}`;
          }
          if (prefix.length) {
            url += `&prefix=${prefix}`;
          }

          apiGet({ url })
            .then(res => {
              if (res.error) {
                console.error('Копирование триггера (древо)');
                return;
              }

              copyToClipboard(res.data);
              setBtnType(null);
              setPrefix('');
              copyTriggerAlert();
            })
            .finally(() => setSending(false));
        }
      };

      const onClick = type => {
        setBtnType(type);
      };

      if (!treeReport) {
        return null;
      }

      return (
        <Accordeon _id={_id} title={title} key={_id}>
          {children?.map((item, index) => (
            <TreeItem
              key={index}
              data={{
                ...item,
                callback,
              }}
            />
          ))}

          <div className="row">
            <div className="col-12">{content}</div>

            {children === null && (
              <>
                {!btnType ? (
                  <>
                    <div className="col-auto">
                      <button className="btn btn-primary mr-1" onClick={() => onClick('This', _id)}>
                        Текущий
                      </button>
                    </div>
                    <div className="col-auto">
                      <button className="btn btn-primary mr-1" onClick={() => onClick('Previous', _id)}>
                        Предыдущий
                      </button>
                    </div>

                    <div className="col-auto">
                      <button className="btn btn-primary mr-1" onClick={() => onClick('Report', _id)}>
                        Конкретный
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {btnType === 'Previous' && (
                      <div className="col-auto">
                        <Input value={prefix} onChange={setPrefix} />
                      </div>
                    )}

                    {btnType === 'Report' && (
                      <div className="col-auto">
                        <label className="form-field form-label size">
                          <select value={prefix} onChange={setPrefix}>
                            <option value="null">Выберите организацию</option>
                            {companyData?.models.map(i => (
                              <option value={i?.id} key={i?.id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </label>
                      </div>
                    )}

                    <div className="col-auto">
                      <button className="btn btn-primary" onClick={() => copyTrigger(_id)}>
                        Скопировать
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Accordeon>
      );
    },
    [treeReport]
  );
  TreeItem.propTypes = {
    data: PropTypes.object,
  };

  // create triggers

  // const onCreateTrigger = () => {
  // 	if (triggerName === "" && condition === "") {
  // 		alert("Необходимо заполнить поля")
  // 	}
  // 	if (!sending) {
  // 		setSending(true)
  // 		apiPost({
  // 			url: `/trigger/create?template_id=${+id}`,
  // 			postData: {
  // 				period_id: +periodId,
  // 				name: triggerName,
  // 				condition: condition,
  // 			},
  // 		})
  // 			.then(res => {
  // 				if (res) {
  // 					if (!res.error) {
  // 						showCreateTrigger(false)
  // 						alert("Триггер успешно создан")
  // 						fetchData()
  // 					} else {
  // 						alert(res?.data?.condition)
  // 					}
  // 				}
  // 			})
  // 			.finally(() => setSending(false))
  // 	}
  // }

  //Удаление триггера
  const deleteTrigger = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/trigger/delete?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Удаление триггера');
            return;
          }

          triggerDelAlert();
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const deleteRule = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/rule/delete?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Удаление правила');
            return;
          }

          ruleDelAlert();
          refreshRule();
        })
        .finally(() => setSending(false));
    }
  };

  const downloadFile = () => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-template/download-reference?id=${id}` })
        .then(res => {
          if (res.error) {
            console.error('Скачивание документа (шаблон)');
            return;
          }

          downloadBase64File(res.data.asBase64, res.data.name);
        })
        .finally(() => setSending(false));
    }
  };

  const setRecheckNextInstances = () => {
    if (!sending) {
      setSending(true);

      apiPost({
        url: `/report-template/set-recheck-next-instances?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            alert('Произошла ошибка');
            return;
          }

          actualizeResponse();
        })
        .finally(() => setSending(false));
    }
  };

  const unsetRecheckNextInstances = () => {
    if (!sending) {
      setSending(true);
      apiPost({
        url: `/report-template/unset-recheck-next-instances?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            alert('Произошла ошибка');
            return;
          }

          actualizeResponse();
        })
        .finally(() => setSending(false));
    }
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
  ];

  if (treeLoading) {
    return (
      <div className="d-flex flex-fill jc-center">
        <Index />
      </div>
    );
  }

  const showDependentsOnClick = () => {
    setShowDependentModal(true);

    actualizeDependents();
  };

  const actualizeDependents = () => {
    apiGet({ url: `/report-template/dependents?id=${id}` }).then(res => {
      if (res.error) {
        alert('Произошла ошибка');
      }

      setDependents(res.data);
    });
  };

  const addDependent = () => {
    apiPost({
      url: `/report-template/add-dependent?id=${id}&dependentId=${dependentForAddID}`,
    }).then(res => {
      if (res.error) {
        alert('Произошла ошибка');
      }

      actualizeDependents();
    });
  };

  const deleteDependent = dependentId => {
    apiPost({
      url: `/report-template/delete-dependent?id=${id}&dependentId=${dependentId}`,
    }).then(res => {
      if (res.error) {
        alert('Произошла ошибка');
      }

      actualizeDependents();
    });
  };

  return (
    <div className="view-report">
      <Breadcrumbs linkRoute={linkRoute} />
      <div className="card">
        <div className="card-header d-flex jc-between">
          <div>Просмотр шаблона</div>

          <div>
            <button onClick={showDependentsOnClick} className="btn btn-primary mr-2">
              Зависимые шаблоны
            </button>
            {response?.recheckNextInstances ? (
              <button onClick={unsetRecheckNextInstances} className="btn btn-danger mr-2">
                Не проверять следующие отчеты
              </button>
            ) : (
              <button onClick={setRecheckNextInstances} className="btn btn-success mr-2">
                Проверять следующие отчеты
              </button>
            )}
            <button
              onClick={() => (response?.haveReference ? history.push(`/template-summary-report/${id}`) : viewModal())}
              className="btn btn-primary mr-2"
            >
              Шаблоны сводных отчетов по данным
            </button>
            <button
              onClick={() =>
                response?.haveReference ? history.push(`/template-summary-report-triggers/${id}`) : viewModal()
              }
              className="btn btn-warning mr-2"
            >
              Шаблоны сводных отчетов по триггерам
            </button>
          </div>
        </div>

        <div className="card-content">
          <div className="row mb-2">
            <div className="col-12">
              <Input label="Название шаблона" disabled value={response?.name || ''} />
            </div>

            <div className="col-12">
              <label className="d-flex">
                <input
                  name="allowed_absence"
                  type="checkbox"
                  className="checkbox"
                  checked={response?.showTriggersDetails}
                />
                <span>Показывать детализацию по триггерам в пользовательской версии</span>
              </label>
            </div>

            <div className="col-12">
              <label className="d-flex">
                <input
                    name="allowed_absence"
                    type="checkbox"
                    className="checkbox"
                    checked={response?.showErrorStatus}
                />
                <span>Показывать статус “Принят”</span>
              </label>
            </div>

            <div className="col-12 mt-5">
              <Input label="Логика отправки писем" disabled value={response?.requirementsLogicText || ''} />
            </div>

            {state.user.accessUser[`report-template`][`download-reference`] && (
              <span>
                {response?.haveReference && (
                  <div className="col-12 col-sm-6 col-md-auto mt-2 mb-2">
                    <button onClick={() => downloadFile()} className="btn action-btn btn-primary">
                      Скачать эталон
                    </button>
                  </div>
                )}
              </span>
            )}

            {state.user.accessUser[`report-template`][`create-reference`] && (
              <div className="col-12 col-sm-6 col-xl-auto mt-2 mb-2">
                <button className="btn action-btn btn-success" onClick={() => showModalNewEvent(true)}>
                  Загрузить новый эталон
                </button>
              </div>
            )}

            <div className="col-12 col-sm-6 ml-auto col-xl-auto">
              {pageInfo === 1 ? (
                <Input placeholder="Поиск триггера.." onChange={changeTriggerSearch} value={filterTrigger} />
              ) : (
                <Input placeholder="Поиск правила.." onChange={changeRuleSearch} value={filterRule} />
              )}
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-auto">
              <button className={`btn btn-primary ${pageInfo == 1 && `btn-lock`}`} onClick={() => changeContent(1)}>
                Триггеры
              </button>
            </div>
            <div className="col-auto">
              <button className={`btn btn-primary ${pageInfo == 2 && `btn-lock`}`} onClick={() => changeContent(2)}>
                Правила
              </button>
            </div>
          </div>

          {pageInfo == 1 ? (
            <div className="scroll-x">
              <table className="low-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Используемые триггеры</th>
                  </tr>
                </thead>
                {triggers?.models.length ? (
                  <tbody>
                    {triggers?.models.map(i => {
                      return i.template?.id === response?.id ? (
                        <tr key={i?.id}>
                          <td>{i.name}</td>
                          <td style={{ width: '180px' }}>
                            <div className="row row-sm">
                              {state.user.accessUser.trigger.update && (
                                <div className="col-auto">
                                  <Link
                                    className="action-btn btn btn-success"
                                    to={`/edit-trigger-reports/${id}/${i.id}`}
                                    title="Отчеты триггера"
                                  >
                                    <Gear />
                                  </Link>
                                </div>
                              )}
                              {state.user.accessUser.trigger.update && (
                                <div className="col-auto">
                                  <Link
                                    className="action-btn btn btn-warning"
                                    to={`/edit-trigger/${id}/${i.id}`}
                                    title="Изменить"
                                  >
                                    <Gear />
                                  </Link>
                                </div>
                              )}
                              {state.user.accessUser.trigger.delete && (
                                <div className="col-auto">
                                  <button
                                    className="action-btn btn btn-danger"
                                    onClick={() => deleteTriggerAlert(i.id)}
                                    title="Удалить"
                                  >
                                    <TrashCan />
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>Триггеров нет</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          ) : (
            <div className="scroll-x">
              <table className="low-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Используемые Правила</th>
                  </tr>
                </thead>
                {rules?.models.length ? (
                  <tbody>
                    {rules?.models.map(i => {
                      return i.template?.id === response?.id ? (
                        <tr key={i?.id}>
                          <td>{i.name}</td>
                          <td style={{ width: '140px' }}>
                            <div className="row row-sm">
                              {state.user.accessUser.trigger.update && (
                                <div className="col-auto">
                                  <Link
                                    className="action-btn btn btn-warning"
                                    to={`/edit-rule/${id}/${i.id}`}
                                    title="Изменить"
                                  >
                                    <Gear />
                                  </Link>
                                </div>
                              )}
                              {state.user.accessUser.trigger.delete && (
                                <div className="col-auto">
                                  <button
                                    className="action-btn btn btn-danger"
                                    onClick={() => deleteRuleAlert(i.id)}
                                    title="Удалить"
                                  >
                                    <TrashCan />
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ) : null;
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>Правил нет</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>

        <div className="card-footer">
          <div className="mt-2 ta-center">
            {pageInfo === 1 ? (
              // <ReactPaginate
              // 	pageCount={triggers?.lastPage == 1 ? 0 : triggers?.lastPage}
              // 	pageRangeDisplayed={5}
              // 	marginPagesDisplayed={3}
              // 	onPageChange={e => setPage(e.selected + 1)}
              // 	activeClassName={"active"}
              // 	containerClassName={"pagination d-inline-flex ta-center"}
              // 	pageLinkClassName={"link"}
              // 	pageClassName={"link"}
              // 	previousLabel={false}
              // 	nextLabel={false}
              // />
              <Pagination lastPage={triggers?.lastPage} page={pageTrigger} nameGet={'pageTrigger'} />
            ) : (
              <Pagination lastPage={rules?.lastPage} page={pageRule} nameGet={'pageRule'} />
              // <ReactPaginate
              // 	pageCount={rules?.lastPage == 1 ? 0 : rules?.lastPage}
              // 	pageRangeDisplayed={5}
              // 	marginPagesDisplayed={3}
              // 	onPageChange={e => setPageRules(e.selected + 1)}
              // 	activeClassName={"active"}
              // 	containerClassName={"pagination d-inline-flex ta-center"}
              // 	pageLinkClassName={"link"}
              // 	pageClassName={"link"}
              // 	previousLabel={false}
              // 	nextLabel={false}
              // />
            )}
          </div>

          <div className="row row-sm">
            {state.user.accessUser.trigger.create && (
              <div className="col-auto mt-2 mb-2">
                {pageInfo == 1 ? (
                  <Link to={`/create-trigger/${id}`} className="btn btn-primary">
                    Создать триггер
                  </Link>
                ) : (
                  <Link to={`/create-rule/${id}`} className="btn btn-primary">
                    Создать правило
                  </Link>
                )}
              </div>
            )}

            <div className="col-auto mt-2 mb-2">
              <Link to={`/tree/${treeReport?.id}`} className="btn btn-light" target="_blank">
                Просмотр древа шаблона
              </Link>
            </div>
          </div>
        </div>
        <Modal show={modal} onClose={() => setModal(false)} modalTitle="Ошибка">
          <div style={{ textAlign: 'center' }}>Необходимо загрузить эталон</div>
        </Modal>
        <Modal onClose={() => showModalNewEvent(false)} show={viewModalUpload} modalTitle="Загрузка эталона">
          <div className="row ">
            <div className="col-12 name-event">
              <Dropzone
                maxSize={1000000000}
                onFileRemove={onFileRemove}
                onFileUpload={handlerUploadFile}
                styles={{ height: 120 }}
                files={files}
              />
            </div>

            {files.length > 0 && (
              <>
                {/* <div className="col-auto">
								<div className="file-name">{files[0]?.name}</div>
								<div className="file-text">{formatBytes(files[0]?.size)}</div>
							</div>
							<div className="col-auto">
								<button
									className="file-remove btn btn-danger pos-abs-y"
									onClick={() => setFiles([])}
								>
									<TrashCan />
								</button>
							</div> */}
                <div className="col-12">
                  <button onClick={() => sendUpload()} className="btn btn-primary mt-2">
                    Загрузить
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal>
        <Modal show={showDependentModal} modalTitle="Зависимые шаблоны" onClose={() => setShowDependentModal(false)}>
          <table className="mt-3 middle-table">
            <thead>
              <tr>
                <th>Наименование</th>
              </tr>
            </thead>

            <tbody>
              {dependents &&
                dependents.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <button
                          className="action-btn btn btn-danger"
                          title="Удалить"
                          onClick={() => deleteDependent(item.id)}
                        >
                          <TrashCan />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="d-flex mt-5">
            <select onChange={e => setDependentForAddID(e.target.value)} className="form-input _select">
              <option value="null">Выберите отчет</option>
              {reportTemplates &&
                reportTemplates.map(i => (
                  <option value={i.id} key={i.id}>
                    {i.name}
                  </option>
                ))}
            </select>
          </div>

          <div className={'mt-4'}>
            <button className="btn btn-primary" disabled={!dependentForAddID} onClick={addDependent}>
              Добавить
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ViewReport;
